<!--
  主页-个人主页
-->
<template>
  <div class="body">
    <!-- 标题区域 谁谁的主页-->
    <!-- :selectTitleName="realName + $t('vue_label_homepage_owner')" -->
    <!-- 我的主页 -->
    <Header-card
      :selectTitleName="$t('vue_label_homepage_owner')"
      :rolePageDropDownName="rolePageDropDownName"
      :HomePageListData="HomePageListData"
      @cancelSwitchs="cancelSwitchs"
      :isEditStatus="isEditStatus"
      @selectMenuClickEvent="selectMenuClickEvent"
      @saveEvent="savePageLayout"
      :isAdmin="true"
      @refreshEvent="refreshEvent"
      @sendRealName="sendRealName"
      @screenFull="screenFull"
    />
    <!-- 右侧内容区域 -->
    <Common-index
      class="common_index_div"
      :commonDataArr="commonDataArr"
      :isEditStatus="isEditStatus"
      @locationLayout="locationLayout"
      :homePageObject="homePageObjectData"
      ref="CommonIndex"
    />
    <!-- 添加组件弹框 -->
    <ComPersonAddAssembly
      :isShowComPersonAddAssembly="isShowComPersonAddAssembly"
      :homePageObjectData="homePageObjectData"
      @assemblyCancel="assemblyCancel"
      @assemblySave="assemblySave"
      @fixedComponentdDataSave="fixedComponentdDataSave"
      @saveDashboardCpn="saveDashboardCpn"
      @instrumentClusterSave="instrumentClusterSave"
      @cardCpnEditSave="cardCpnEditSave"
      @assemblyDashboardEditSave="assemblyDashboardEditSave"
      @dashboardPageSave="dashboardPageSave"
      @editPinnedComponents="editPinnedComponents"
      @customComponentdEdit="customComponentdEdit"
      ref="ComPersonAddAssemblyRef"
    />
    <!-- 添加个人主页删除组件弹框 -->
    <DialogDelect
      :nameCpn="nameCpn"
      :relatedidCpn="relatedidCpn"
      :dialogDelectTo="dialogDelectTo"
      @ComponentsDelCancle="ComponentsDelCancle"
      @ComponentsDelConfirm="ComponentsDelConfirm"
    />
    <!-- 组件编辑器弹框
          :isShowSetUpComponents="true"
     -->
    <ComponentEditor
      :isShowComponentsEditorAlert="isShowComponentsEditorAlert"
      :isAddToHomePage="isAddToHomePage"
      ref="ComponentEditorRef"
      :dashboardy="dashboardy"
      :sourceType="sourceType"
      :chartInfo="chartInfo"
      :linkageArr="linkageArr"
      @cancel="componentEditorCancel"
      @affirm="componentEditorAffirm"
    ></ComponentEditor>
    <!-- 卡片视图新建修改事件弹框 -->
    <createEditObj ref="createEditObj"> </createEditObj>
    <!-- 图表全屏组件 -->
    <chartFullScreen
      :chartFullScreenTo="chartFullScreenTo"
      :DashboardDataFullScreen="DashboardDataFullScreen"
      @chartFullScreenClose="chartFullScreenClose"
      :dataShowName="dataShowName"
    />
    <!-- 主页内容全屏弹框 -->
    <!-- :homePagetitle="`${realName}${$t('vue_label_homepage_owner')}`" -->
    <homePageFullScreen
      v-if="homePageFullScreenTo"
      :homePageFullScreenTo="homePageFullScreenTo"
      @homePageFullScreenClose="homePageFullScreenClose"
      :homePagetitle="`${$t('vue_label_homepage_owner')}`"
    />
  </div>
</template>
<script>
import chartFullScreen from "@/components/FullScreen/chartFullScreen"; //图表全屏组件
import homePageFullScreen from "@/components/FullScreen/homePageFullScreen"; // 主页内容全屏组件
import { HeaderCard } from "../components/index";
import CommonIndex from "../components/CommonIndex";
// import LeftMenu from "./components/LeftMenu"; //左侧菜单栏组件
import ComPersonAddAssembly from "./components/ComPersonAddAssembly";
import * as request from "./api.js";
import getCpnLocation from "@/utils/getCpnLocation"; //获取当前底部组件的位置信息
import DialogDelect from "../components/DialogDelect";
import createEditObj from "@/views/commonObjects/components/create-edit-obj"; //新建，修改事件弹框
import * as types from "@/store/mutations-types";
//==============================================
import ComponentEditor from "@/views/dashboardObject/dashboardIndex/components/ComponentEditor";
import mixin from "@/mixin/homepageAndDashboardMixin.js";

export default {
  components: {
    HeaderCard,
    CommonIndex,
    // LeftMenu,
    ComPersonAddAssembly,
    DialogDelect,
    createEditObj,
    chartFullScreen,
    homePageFullScreen,
    ComponentEditor,
  },
  mixins: [mixin],
  created() {
    this.getHomePageLayoutData("personal");
  },
  mounted() {
    // 新增图表
    this.$bus.$on("showComponentsEditorAlert", this.ebFn1);
    // 点击确认关闭弹框
    this.$bus.$on("isShowComPersonAddAssemblyBus",this.ebFn2);

    //=================================================
    //控制图表全屏展示
    this.$bus.$on("clickChartFullScreen", this.ebFn3);
    // 删除卡片视图组件的方法
    this.$bus.$on("deleteCpn", this.ebFn4);
    // 编辑卡片视图组件方法
    this.$bus.$on("editCpn", this.ebFn5);
    // 删除固定组件的方法
    this.$bus.$on("deleteFixedComponents", this.ebFn6);
    // 编辑固定组件的方法
    this.$bus.$on("editPinned", this.ebFn7);
    //  编辑仪表板面板的方法
    this.$bus.$on("instrumentPanel", this.ebFn8);

    //删除仪表板组件的方法
    this.$bus.$on("fnchoosedelete", this.ebFn9);
    //编辑单个仪表板组件的方法
    this.$bus.$on("fnchooseEdit", this.ebFn10);
  },
  destroyed() {
    this.$bus.$off("showComponentsEditorAlert", this.ebFn1);
    this.$bus.$off("isShowComPersonAddAssemblyBus",this.ebFn2);
    this.$bus.$off("clickChartFullScreen", this.ebFn3);
    this.$bus.$off("deleteCpn", this.ebFn4);
    this.$bus.$off("editCpn", this.ebFn5);
    this.$bus.$off("deleteFixedComponents", this.ebFn6);
    this.$bus.$off("editPinned", this.ebFn7);
    this.$bus.$off("instrumentPanel", this.ebFn8);
    this.$bus.$off("fnchoosedelete", this.ebFn9);
    this.$bus.$off("fnchooseEdit", this.ebFn10);
  },
  data() {
    return {
      isAddToHomePage: true, // 表示添加到仪表板还是主页，来控制仪表板id是否为空
      isShowComponentsEditorAlert: false,
      HomePageListData: [], //全部主页对象列表
      sourceType: "",
      // timer: null, //定时器
      dataShowName: "", //图表name
      DashboardDataFullScreen: {},
      chartFullScreenTo: false,
      homePageFullScreenTo: false, //主页内容是否全屏
      dialogDelectTo: false,
      idCpn: "",
      nameCpn: "",
      relatedidCpn: "",
      homePageObjectData: {}, //页面对象数据
      // 菜单栏显示隐藏判断
      leftMenuBar: false,
      // 表头切换的显示和隐藏
      isEditStatus: false,
      isShowComPersonAddAssembly: false, //是否显示添加组件弹框
      rolePageDropDownName: "", //简档主页名字
      rolePageDropDownNameS: "", //标准主页的名称
      realName: "", //个人主页名字
      // colData: [
      //   {
      //     name: "1",
      //   },
      //   {
      //     name: "2",
      //   },
      //   {
      //     name: "3",
      //   },
      //   {
      //     name: "4",
      //   },
      // ],
      locationLayoutVal: [], //页面布局坐标
      /**
       * 公共数组，存储所有组件的数据
       */
      commonDataArr: [],
      /**
       * 点击卡片视图的编辑时保存当前的组件对象，编辑保存时传递位置使用
       * 编辑卡片视图和仪表板组件时都用此组件位置的对象进行调用接口的布局坐标保存
       */
      editCpnSelectCpnObject: {},
    };
  },
  methods: {
    ebFn1(){
      this.isShowComponentsEditorAlert = true;
      // 新增图表时清空数据
      this.chartInfo = {};
      this.homePageChartInfo = {};
    },
    ebFn2 (){
      this.isShowComPersonAddAssembly = false;
    },
    ebFn3(e, dataShowName){
      this.DashboardDataFullScreen = e; //图表数据
      this.chartFullScreenTo = true;
      this.dataShowName = dataShowName; //图表name
    },
    ebFn4(val, name){
      this.dialogDelectTo = true;
      this.idCpn = val;
      this.nameCpn = name;
      this.relatedidCpn = "";
    },
    ebFn5(val){
      // 保存当前点击的组件对象（编辑保存时使用）
      this.editCpnSelectCpnObject = val.cpnObject;
      // 让添加组件弹窗显示
      this.isShowComPersonAddAssembly = true;
      // 是否是编辑状态
      this.$refs.ComPersonAddAssemblyRef._data.isEditToThis = true;
      // 卡片视图id
      this.$refs.ComPersonAddAssemblyRef._data.CardruleForm.i = val.cpnObject.i;
      // 卡片视图名字
      this.$refs.ComPersonAddAssemblyRef._data.CardruleForm.CardName =
        val.cpnName;
      //  卡片视图选择对象
      this.$refs.ComPersonAddAssemblyRef._data.CardruleForm.CardObject =
        val.cpnObject.objid;
      //  卡片视图选择id
      this.$refs.ComPersonAddAssemblyRef._data.CardruleForm.CardView =
        val.cpnObject.relatedid;
      //  选择展示字段数组
      this.$refs.ComPersonAddAssemblyRef._data.CardruleForm.CardField =
        val.cpnObject.fieldids.split(",");
      //  选择按钮操作项
      this.$refs.ComPersonAddAssemblyRef._data.CardruleForm.CardChoosebtn =
        val.cpnObject.CardChoosebtnIdArr;
      // 保存组件中传来的组件id，在当前页面保存，删除时使用
      this.idCpn = val.cpnId;
    },
    ebFn6(val, name, relatedid){
      this.dialogDelectTo = true;
      this.idCpn = val;
      this.nameCpn = name;
      this.relatedidCpn = relatedid;
    },
    ebFn7(val){
      // 保存当前点击的组件对象（编辑保存时使用）
      this.editCpnSelectCpnObject = val.cpnObject;
      // 让添加组件弹窗显示
      this.isShowComPersonAddAssembly = true;
      // 是否是编辑状态
      this.$refs.ComPersonAddAssemblyRef._data.isFixedComponents = true;
      // 固定组件Name fixedForm
      this.$refs.ComPersonAddAssemblyRef._data.fixedForm.fixedCpnName =
        val.cpnName;
      this.$refs.ComPersonAddAssemblyRef._data.fixedForm.fixedCpnId =
        val.cpnObject.relatedid;
      // 保存组件中传来的组件id，在当前页面保存，删除时使用
      this.idCpn = val.cpnId;
    },
    ebFn8(val){
      // 是否为编辑状态
      this.$refs.ComPersonAddAssemblyRef._data.isDashboardPagEditing = true;
      // 保存当前点击的组件对象（编辑保存时使用）
      this.editCpnSelectCpnObject = val.cpnObject;
      // 让添加组件弹窗显示
      this.isShowComPersonAddAssembly = true;
      // 仪表板组件布局id
      this.$refs.ComPersonAddAssemblyRef._data.DashboardruleForm.i =
        val.cpnObject.i;
      // 仪表板面板名字
      this.$refs.ComPersonAddAssemblyRef._data.DashboardruleForm.DashboardName =
        val.cpnName;
      // 选择仪表板
      this.$refs.ComPersonAddAssemblyRef._data.DashboardruleForm.DashboardChooseID =
        val.cpnObject.dashboardid;
    },
    ebFn9(val, name){
      //让删除组件弹框显示
      this.dialogDelectTo = true;
      this.idCpn = val;
      this.nameCpn = name;
      this.relatedidCpn = "";
    },
    ebFn10(val, val2, val3){
      // 判断是否属于主页中直接添加的图表
      if (val.type === "homepageChart") {
        this.fnchooseEdit(val3, val);
        return;
      }
      // val是图表的数据，val2是组件的数据
      // 是否仪表板是编辑状态
      this.$refs.ComPersonAddAssemblyRef._data.isDashboardEditToThis = true;
      // 保存当前点击的组件对象（编辑保存时使用）
      this.editCpnSelectCpnObject = val2;
      // 仪表板组件布局id
      this.$refs.ComPersonAddAssemblyRef._data.DashboardCpnruleForm.i = val2.i;
      // 单个仪表板组件名字
      this.$refs.ComPersonAddAssemblyRef._data.DashboardCpnruleForm.DashboardName =
        val2.name;
      // 选择仪表板
      this.$refs.ComPersonAddAssemblyRef._data.DashboardCpnruleForm.DashboardChooseID =
        val.dashboardid;
      // 选择组件
      this.$refs.ComPersonAddAssemblyRef._data.DashboardCpnruleForm.DashboardassemblyID =
        val.i;
      // 让添加组件弹窗显示
      this.isShowComPersonAddAssembly = true;
    },
    // 添加组件弹框
    // addDashBoard() {
    //   this.$emit("addDashBoard");
    // },
    //单个图表的全屏关闭
    chartFullScreenClose() {
      this.chartFullScreenTo = false;
    },
    //使个人主页内容区域全屏
    screenFull() {
      this.homePageFullScreenTo = true;
      setTimeout(() => {
        this.$bus.$emit("screenFullPersonalData", this.commonDataArr);
      }, 0);
    },
    //关闭全屏弹框
    homePageFullScreenClose() {
      this.homePageFullScreenTo = false;
    },
    // 卡片视图新建任务事件弹框
    // newTaskPopup() {},
    //个人主页组件删除
    async ComponentsDelConfirm() {
      let res = await request.deleteHomePageComponent({
        id: this.idCpn,
      });
      if (res.result) {
        this.dialogDelectTo = false;
        //删除后---获取主页布局数据
        await this.getHomePageLayoutData("personal");
        //删除后---重新保存布局
        this.savePageLayout();
        // 在删除图表之后会触发组件的destroyed钩子，会把该组件的所有引用里的$bus.$on事件销毁，所以需要遍历该仪表板下剩下的所有图表，手动添加上监听事件
        if (this.$refs.CommonIndex.$refs.DashboardCard.length > 0) {
          this.$refs.CommonIndex.$refs.DashboardCard.map((item) => {
            item.getLinkagegetDataMethod();
          });
        }
        // 删除组件成功
        this.$message.success(this.$i18n.t("label_tabpage_delsuccessz"));
      } else {
        this.$message.error(res.returnInfo);
      }
    },
    // 关闭组件删除弹框
    ComponentsDelCancle() {
      this.dialogDelectTo = false;
    },
    // 全局刷新
    refreshEvent() {
      this.$bus.$emit("refreshOverallData");
    },
    // 点击取消
    cancelSwitchs() {
      this.isEditStatus = !this.isEditStatus;
      //  取消回位
      this.getHomePageLayoutData("personal");
    },
    // 重新排序和添加组件事件
    selectMenuClickEvent(eventType) {
      if (eventType === "orderAgain") {
        // 重新排序
        this.isEditStatus = !this.isEditStatus;
        this.leftMenuBar = !this.leftMenuBar;
      } else if (eventType === "addCpn") {
        // 添加组件事件
        this.isShowComPersonAddAssembly = true;
      }
    },
    // 获取主页布局数据
    getHomePageLayoutData(pageType) {
      // eslint-disable-next-line no-async-promise-executor
      return new Promise(async (resolve, reject) => {
        try {
          const {
            HomePageListData,
            homePageObjectData,
            rolePageDropDownNameObject,
          } = await request.getHomePageListToCurrentPageObjectData(pageType);
          // 传值给标准主页
          this.rolePageDropDownNameS = HomePageListData
            ? HomePageListData[0].name
            : "";
          document.title = this.$setTitle(homePageObjectData.name);
          this.$store.commit(types.SET_TAB_NAME, homePageObjectData.name);
          this.homePageObjectData = homePageObjectData;
          this.HomePageListData = HomePageListData;
          // 获取主页组件布局
          let { data } = await request.getHomePageLayout({
            id: this.homePageObjectData.id,
            iseditLayout: "false", // 表示不是走的编辑自定义主页，这时候需要记录当前主页
          });
          data.data.map((item) => {
            if (item.layout) {
              item.layout.map((item1) => {
                item1.x = parseInt(item1.x);
                item1.y = parseInt(item1.y);
                item1.w = parseInt(item1.w);
                item1.h = parseInt(item1.h);
              });
            }
          });

          this.commonDataArr = data.data || [];
          // 当前下拉的name
          this.rolePageDropDownName = rolePageDropDownNameObject
            ? rolePageDropDownNameObject.name
            : "";
          // 组件超出设定的50格最大高度，发出警告
          let flag = true;
          data.data.forEach((item) => {
            if (item.y > 50 && flag) {
              // 警告！添加组件后高度超出导致显示不全，请删除部分组件
              this.$message.error(
                this.$i18n.t("vue_label_homepage_somecomponents")
              );
              flag = false;
            }
          });
          resolve(true);
        } catch (error) {
          reject(false);
        }
      });
    },
    // 保存页面布局
    async savePageLayout() {
      let parmas = {
        homePageId: this.homePageObjectData.id,
        components: JSON.parse(JSON.stringify(this.locationLayoutVal)),
      };
      parmas.components.map((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });
      parmas.components = JSON.stringify(parmas.components);
      await request.saveHomePageComponentsLayout(parmas);
      this.isEditStatus = false;
      // this.$message.success("保存页面布局成功!");
      // 保存布局成功，重新请求布局
      this.getHomePageLayoutData("personal");
    },
    //编辑保存自定义组件
    // async customComponentdEdit() {
    //   // 
    //   let components = [
    //     {
    //       // 自定义组件需要的id
    //       // i: "Preview",
    //       name: "自定义组件&myPage&" + sessionStorage.getItem("pageapi"),
    //       type: "Preview",
    //       relatedid: "Preview",
    //       iscustom: "0", //是否自定义
    //       h: "6",
    //       w: "8",
    //       x: "0",
    //       y: "0",
    //       minH: "1",
    //       minW: "1",
    //     },
    //   ];
    //   let res = await request.saveHomePageComponentsLayout({
    //     homePageId: this.homePageObjectData.id,
    //     components: JSON.stringify(components),
    //   });
    //   // 保存布局成功，重新请求布局
    //   this.getHomePageLayoutData("personal");
    // },
    // 页面重新排序，监听赋值页面布局
    locationLayout(location) {
      this.locationLayoutVal = location;
    },
    // 取消添加组件
    assemblyCancel() {
      this.isShowComPersonAddAssembly = false;
    },
    // 保存添加卡片视图组件请求
    async assemblySave(val) {
      let tmpAuthority = {
        isEdit: null,
        isDelete: null,
        isNewTask: null,
        isNewEvent: null,
      };
      // 拼接选择按钮操作项菜单数据
      val.CardChoosebtn.forEach((item) => {
        if (item.isEdit === "true") {
          tmpAuthority.isEdit = "true";
        } else if (item.isDelete === "true") {
          tmpAuthority.isDelete = "true";
        } else if (item.isNewTask === "true") {
          tmpAuthority.isNewTask = "true";
        } else if (item.isNewEvent === "true") {
          tmpAuthority.isNewEvent = "true";
        }
      });

      // 获取组件的位置信息（封装的工具）
      // 传值传递监听的参数，因为传递原始的布局数组会导致页面参数不是最新的
      let cpnLocation = getCpnLocation(this.locationLayoutVal);
      // 组装保存卡片视图对象
      let components = [
        {
          i: val.i, //添加卡片视图是不需要传id，编辑时才要传值
          name: val.CardName,
          type: "CardView",
          iscustom: "1", //是否自定义
          objid: val.CardObject, //对象id
          relatedid: val.CardView, //视图id
          fieldids: val.CardField, //要显示的字段id（用，分割）
          h: 4,
          w: 4,
          x: 0,
          y: cpnLocation.maxY + cpnLocation.confirmH,
          minH: 4,
          minW: 4,
          ...tmpAuthority,
        },
      ];
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });

      // ------------------------------------------------------------------------------
      /**
       * 主页控制组件上限（15个），超出时全局提示，无法添加，看板算1个组件
       * 别的添加组件的方法都要加上此限制，（把此部分复制粘贴即可）
       */
      if (this.locationLayoutVal.length >= 15) {
        // 单个页面最多添加15个组件
        this.$message.warning(this.$i18n.t("label.componenteditor.Limit.number.components"));
        // 关闭新增组件弹窗
        this.isShowComPersonAddAssembly = false;
        return;
      }
      // ------------------------------------------------------------------------------
      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      // 关闭新增组件弹窗
      this.isShowComPersonAddAssembly = false;
      // 保存卡片视图成功
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局
      await this.getHomePageLayoutData("personal");
      //在添加完组件之后定位到添加的组件位置
      this.$bus.$emit("scrollPositionBus");
    },

    // 保存固定组件请求
    async fixedComponentdDataSave(val) {
      // 固定组件需要的数据
      let fixedComponentdObject = {};
      val.map((item) => {
        fixedComponentdObject.fixedName = item.name;
        fixedComponentdObject.fixedType = item.type;
        fixedComponentdObject.fixedWhile = item.w;
        fixedComponentdObject.fixedHeight = item.h;
        fixedComponentdObject.minH = item.minH;
        fixedComponentdObject.minW = item.minW;
        fixedComponentdObject.fixedRelatedid = item.relatedid;
      });

      // 获取组件的位置信息（封装的工具）
      // 传值传递监听的参数，因为传递原始的布局数组会导致页面参数不是最新的
      let cpnLocation = getCpnLocation(this.locationLayoutVal);
      // 组装保存固定对象
      let components = [
        {
          i: val.i, //添加卡片视图是不需要传id，编辑时才要传值
          name: fixedComponentdObject.fixedName,
          type: fixedComponentdObject.fixedType,
          relatedid: fixedComponentdObject.fixedRelatedid,
          iscustom: "0", //是否自定义
          h: fixedComponentdObject.fixedHeight,
          w: fixedComponentdObject.fixedWhile,
          x: 0,
          y: cpnLocation.maxY + cpnLocation.confirmH,
          minH: fixedComponentdObject.minH,
          minW: fixedComponentdObject.minW,
        },
      ];
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });

      if (this.locationLayoutVal.length >= 15) {
        // 单个页面最多添加15个组件
        this.$message.warning(this.$i18n.t("label.componenteditor.Limit.number.components"));
        // 关闭新增组件弹窗
        this.isShowComPersonAddAssembly = false;
        return;
      }
      // ------------------------------------------------------------------------------
      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      // 关闭新增组件弹窗
      this.isShowComPersonAddAssembly = false;
      // 保存固定组件成功
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局
      await this.getHomePageLayoutData("personal");
      //在添加完组件之后定位到添加的组件位置
      this.$bus.$emit("scrollPositionBus");
    },
    // 编辑保存固定组件请求
    async editPinnedComponents(val) {
      let fixedComponentdObject = {};
      val.map((item) => {
        fixedComponentdObject.fixedName = item.name;
        fixedComponentdObject.fixedType = item.type;
        fixedComponentdObject.fixedWhile = item.w;
        fixedComponentdObject.fixedHeight = item.h;
        fixedComponentdObject.minH = item.minH;
        fixedComponentdObject.minW = item.minW;
        fixedComponentdObject.fixedRelatedid = item.relatedid;
      });

      let cpnLocation = getCpnLocation(this.locationLayoutVal);
      // 组装保存固定对象
      let components = [
        {
          // 编辑组件需要的id
          i: this.idCpn,
          name: fixedComponentdObject.fixedName,
          type: fixedComponentdObject.fixedType,
          relatedid: fixedComponentdObject.fixedRelatedid,
          iscustom: "0", //是否自定义
          h: fixedComponentdObject.fixedHeight,
          w: fixedComponentdObject.fixedWhile,
          x: 0,
          y: cpnLocation.maxY + cpnLocation.confirmH,
          minH: fixedComponentdObject.minH,
          minW: fixedComponentdObject.minW,
        },
      ];
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });
      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      // 关闭新增组件弹窗
      this.isShowComPersonAddAssembly = false;
      // 保存固定组件成功
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局
      this.getHomePageLayoutData("personal");
    },
    //编辑保存自定义组件
    async customComponentdEdit() {
      // 
      let components = [
        {
          // 自定义组件需要的id
          // i: "Preview",
          name: "自定义组件&myPage&" + sessionStorage.getItem("pageapi"),
          type: "Preview",
          relatedid: "Preview",
          iscustom: "0", //是否自定义
          h: "6",
          w: "8",
          x: "0",
          y: "0",
          minH: "1",
          minW: "1",
        },
      ];
      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      // 保存布局成功，重新请求布局
      this.getHomePageLayoutData("personal");
    },
    // 编辑保存卡片视图组件请求
    async cardCpnEditSave(val) {
      let tmpAuthority = {
        isEdit: null,
        isDelete: null,
        isNewTask: null,
        isNewEvent: null,
      };
      // 拼接选择按钮操作项菜单数据
      val.CardChoosebtn.forEach((item) => {
        if (item.isEdit === "true") {
          tmpAuthority.isEdit = "true";
        } else if (item.isDelete === "true") {
          tmpAuthority.isDelete = "true";
        } else if (item.isNewTask === "true") {
          tmpAuthority.isNewTask = "true";
        } else if (item.isNewEvent === "true") {
          tmpAuthority.isNewEvent = "true";
        }
      });

      // 组装保存卡片视图对象
      let components = [
        {
          i: val.i, //添加卡片视图是不需要传id，编辑时才要传值
          name: val.CardName,
          type: "CardView",
          iscustom: "1", //是否自定义
          objid: val.CardObject, //对象id
          relatedid: val.CardView, //视图id
          fieldids: val.CardField, //要显示的字段id（用，分割）
          h: this.editCpnSelectCpnObject.h,
          w: this.editCpnSelectCpnObject.w,
          x: this.editCpnSelectCpnObject.x,
          y: this.editCpnSelectCpnObject.y,
          minH: this.editCpnSelectCpnObject.minH,
          minW: this.editCpnSelectCpnObject.minW,
          ...tmpAuthority,
        },
      ];
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });
      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      // 关闭新增组件弹窗
      this.isShowComPersonAddAssembly = false;
      // 保存卡片视图成功
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局

      this.getHomePageLayoutData("personal");
    },
    // 根据图表类型确定图表的最小宽高设置
    getMinWH(dashboardtype) {
      let minW = 3;
      let minH = 3;
      switch (dashboardtype) {
        case 'heatMap': // 热力图，4*4
          minW = 4;
          minH = 4;
          break;
        case 'number': // 数字图表，2*2
          minW = 2;
          minH = 2;
          break;
        case 'digital': // 同环比数字图表，4*3
          minW = 4;
          minH = 2;
          break;
        case 'bar_0': // 柱状图或者条形图，4*3
          minW = 4;
          minH = 3;
          break;
        case 'bar_1': // 柱状图或者条形图，4*3
          minW = 4;
          minH = 3;
          break;
        case 'bar_duidie': // 柱状图或者条形图，4*3
          minW = 4;
          minH = 3;
          break;
        case 'column_0': // 没有第二个分组的柱状图，4*
          minW = 4;
          minH = 3;
          break;
        case 'column_1': // 二维柱形图，4*4
          minW = 4;
          minH = 3;
          break;
        case 'column_duidie': // 6柱状堆叠图，4*4
          minW = 4;
          minH = 3;
          break;
        case 'line_0': // 没有第二个分组的折线图，4*4
          minW = 4;
          minH = 3;
          break;
        case 'line_1': // 有第二个分组的折线图，4*4
          minW = 4;
          minH = 3;
          break;
        case 'biaxial': // 同环比双轴图，4*4
          minW = 4;
          minH = 4;
          break;
        case 'waterfall': // 瀑布图*4
          minW = 4;
          minH = 3;
          break;
        case 'area': // 面积图*4
          minW = 4;
          minH = 3;
          break;
        case 'biaxialFigure': // 双轴图*4
          minW = 4;
          minH = 4;
          break;
        default: // 其他，3*3
          minW = 3;
          minH = 3;
          break;
      }
      return {
        minW: minW,
        minH: minH
      }
    },
    // 添加单个仪表板组件请求方法
    async saveDashboardCpn(val) {
      let cpnLocation = getCpnLocation(this.commonDataArr);
      // 组装保存仪表板对象
      let components = [
        {
          /**
           * 如果 i 有值，说明是从组件的编辑进来的，
           * 则要通过CompersonAddAssembly组件传来的i进行请求更新某
           * 知道i的组件
           */
          // i: val.i,
          name: val.name,
          /* 
          添加有以下几种情况：
            1.主页中直接新增图表；
            2.从仪表板中选择图表添加；
            3.直接添加整个仪表板
          */
          type: val.type
            ? val.type
            : val.DashboardassemblyID
            ? "Dashboard"
            : "PersonalPageCpn",
          iscustom: "1",
          dashboardid: val.DashboardChooseID, //仪表板id
          //图表组件id（如果是整个仪表板的话 这两个字段都赋同一个值）
          relatedid: val.DashboardassemblyID || val.DashboardChooseID,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          h: val.DashboardassemblyID ? 4 : "",
          w: val.DashboardassemblyID ? 4 : "",
          x: 0,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          y: cpnLocation.maxY + cpnLocation.confirmH,
          minW: val.DashboardassemblyID
            ? this.getMinWH(val.DashboardassemblyType).minW
            : 8,
          minH: val.DashboardassemblyID
            ? this.getMinWH(val.DashboardassemblyType).minH
            : 3,
        },
      ];
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });

      // ------------------------------------------------------------------------------
      /**
       * 主页控制组件上限（15个），超出时全局提示，无法添加，看板算1个组件
       * 别的添加组件的方法都要加上此限制，（把此部分复制粘贴即可）
       */
      if (this.locationLayoutVal.length >= 15) {
        // 单个页面最多添加15个组件
        this.$message.warning(this.$i18n.t("label.componenteditor.Limit.number.components"));
        // 关闭新增组件弹窗
        this.isShowComPersonAddAssembly = false;
        return;
      }
      // ------------------------------------------------------------------------------

      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      this.isShowComPersonAddAssembly = false;
      // 保存仪表板成功
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局
      await this.getHomePageLayoutData("personal");

      // 发送给仪表板事件,让其单个更新
      this.$bus.$emit("getLinkagegetData", { id: val.DashboardassemblyID });

      //在添加完组件之后定位到添加的组件位置
      this.$bus.$emit("scrollPositionBus");
    },

    // 编辑单个仪表板组件请求方法
    async assemblyDashboardEditSave(val) {
      // 组装保存仪表板对象
      let components = [
        {
          /**
           * 如果 i 有值，说明是从组件的编辑进来的，
           * 则要通过CompersonAddAssembly组件传来的i进行请求更新某
           * 知道i的组件
           */
          i: val.i,
          name: val.name,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          type: val.type
            ? val.type
            : val.DashboardassemblyID
            ? "Dashboard"
            : "PersonalPageCpn",
          iscustom: "1",
          dashboardid: val.DashboardChooseID, //仪表板页面id
          //图表组件id（如果是整个仪表板的话 这两个字段都赋同一个值）
          relatedid: val.DashboardassemblyID || val.DashboardChooseID,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          h:
            this.editCpnSelectCpnObject.h !== undefined
              ? this.editCpnSelectCpnObject.h
              : val.h,
          w:
            this.editCpnSelectCpnObject.w !== undefined
              ? this.editCpnSelectCpnObject.w
              : val.w,
          x:
            this.editCpnSelectCpnObject.x !== undefined
              ? this.editCpnSelectCpnObject.x
              : val.x,
          y:
            this.editCpnSelectCpnObject.y !== undefined
              ? this.editCpnSelectCpnObject.y
              : val.y,
          minW: val.DashboardassemblyID
            ? this.getMinWH(val.DashboardassemblyType).minW
            : 8,
          minH: val.DashboardassemblyID
            ? this.getMinWH(val.DashboardassemblyType).minH
            : 3,
        },
      ];
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });
      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      this.isShowComPersonAddAssembly = false;
      // 保存仪表板成功
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局
      this.getHomePageLayoutData("personal");
      // 发送给仪表板事件,让其单个更新

      /**
       * 让仪表板当前要更新的组件仪表请求新数据，达到单个组件编辑更新
       * 如果 flag为true，则编辑保存仪表板单个组件时可以单个更新单个组件
       * 在仪表板组件中的超全局事件监听 getLinkagegetData 中有监听
       * DashboardassemblyID 是编辑选中后的id
       */
      this.$bus.$emit("getLinkagegetData", {
        id: val.DashboardassemblyID,
      });
    },

    // 添加仪表盘页面保存的请求方法
    async instrumentClusterSave(val) {
      let cpnLocation = getCpnLocation(this.commonDataArr);
      // 组装保存卡片视图对象
      let components = [
        {
          /**
           * 如果 i 有值，说明是从组件的编辑进来的，
           * 则要通过CompersonAddAssembly组件传来的i进行请求更新某
           * 知道i的组件
           */
          // i: val.i,
          name: val.name,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          type: val.DashboardassemblyID ? "Dashboard" : "PersonalPageCpn",
          iscustom: "1",
          dashboardid: val.DashboardChooseID, //仪表板页面id
          //图表组件id（如果是整个仪表板的话 这两个字段都赋同一个值）
          relatedid: val.DashboardassemblyID || val.DashboardChooseID,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          h: val.DashboardassemblyID ? 3 : "",
          w: val.DashboardassemblyID ? 4 : "",
          x: 0,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          y: cpnLocation.maxY + cpnLocation.confirmH,
          minW: val.DashboardassemblyID ? 3 : 8,
          minH: val.DashboardassemblyID ? 4 : 3,
        },
      ];
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });

      // ------------------------------------------------------------------------------
      /**
       * 主页控制组件上限（15个），超出时全局提示，无法添加，看板算1个组件
       * 别的添加组件的方法都要加上此限制，（把此部分复制粘贴即可）
       */
      if (this.locationLayoutVal.length >= 15) {
        // 单个页面最多添加10个组件
        this.$message.warning(this.$i18n.t("label.componenteditor.Limit.number.components"));
        // 关闭新增组件弹窗
        this.isShowComPersonAddAssembly = false;
        return;
      }
      // ------------------------------------------------------------------------------

      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      this.isShowComPersonAddAssembly = false;
      // 保存仪表盘页面成功
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局
      await this.getHomePageLayoutData("personal");
      //在添加完组件之后定位到添加的组件位置
      this.$bus.$emit("scrollPositionBus");
    },

    // 编辑仪表盘页面保存的方法
    async dashboardPageSave(val) {
      // 组装保存仪表板对象
      let components = [
        {
          /**
           * 如果 i 有值，说明是从组件的编辑进来的，
           * 则要通过CompersonAddAssembly组件传来的i进行请求更新某
           * 知道i的组件
           */
          i: val.i,
          name: val.name,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          type: val.DashboardassemblyID ? "Dashboard" : "PersonalPageCpn",
          iscustom: "1",
          dashboardid: val.DashboardChooseID, //仪表板页面id
          //图表组件id（如果是整个仪表板的话 这两个字段都赋同一个值）
          relatedid: val.DashboardassemblyID || val.DashboardChooseID,
          // 如果有选择仪表板组件id，说明是添加仪表板组件
          // h: this.editCpnSelectCpnObject.h,
          h: "",
          w: this.editCpnSelectCpnObject.w,
          x: this.editCpnSelectCpnObject.x,
          y: this.editCpnSelectCpnObject.y,
          minW: val.DashboardassemblyID ? 3 : 8,
          minH: val.DashboardassemblyID ? 4 : 3,
        },
      ];
      components.forEach((item) => {
        item.h = item.h + "";
        item.w = item.w + "";
        item.x = item.x + "";
        item.y = item.y + "";
        item.minH = item.minH + "";
        item.minW = item.minW + "";
      });
      await request.saveHomePageComponentsLayout({
        homePageId: this.homePageObjectData.id,
        components: JSON.stringify(components),
      });
      this.isShowComPersonAddAssembly = false;
      // 保存仪表板成功
      this.$message.success(this.$i18n.t("label.search.saveok"));
      // 保存布局成功，重新请求布局
      this.getHomePageLayoutData("personal");
      // 发送给仪表板事件,让其单个更新
      this.$bus.$emit("getLinkagegetData", { id: val.DashboardChooseID });
    },
    // 监听headerbar组件传来的用户名称
    sendRealName(realName) {
      this.realName = realName;
    },
  },
};
</script>
<style scoped lang="scss">
.common_index_div {
  // margin-top: 60px;
}
.bulletin {
  margin-bottom: 10px;
}
.ranking-item {
  margin-bottom: 10px;
}
::v-deep .box-card[data-v-fa5d65d4] {
  width: 100%;
  height: 100%;
}
.body {
  padding: 10px;
}
</style>
